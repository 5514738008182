
import { firestore } from "@/firebase";
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AboutIndex extends Vue {

  loading = false;

  inputs = {
    email: '',
    phone: '',
    address: ''
  };

  async mounted(){
    try {
      const data = (await firestore().collection('platform-settings').doc('about').get()).data();

      this.inputs.email = data!.email;
      this.inputs.phone = data!.phone;
      this.inputs.address = data!.address
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    }
  }

  async save() {
    try {
      this.loading = true;
      await firestore().collection("platform-settings").doc("about").set({
        email: this.inputs.email,
        phone: this.inputs.phone,
        address: this.inputs.address
      });
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Contactgegevens gewijzigd",
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Kan contactgegevens niet updaten",
      });
    } finally {
      this.loading = false;
    }
  }
}
